import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {useParams} from "react-router-dom";
import {Collection} from "../types/Collection";
import {useCallback, useEffect, useState} from "react";
import HubInscription from "./HubInscription";

type hubCollectionProps = {
    ownerAddress: string | null;
    collectionAddress: string | null;
    type: string;

    collectionsMap: Map<string, Collection>;
}

const CollectionLoader = (props: hubCollectionProps) => {
    const [collection, setCollection] = useState<Collection|undefined>()

    // Inscription ID Path Param
    const { id } = useParams()

    var isLoaded: boolean = false;

    isLoaded = (id != null)
    isLoaded = (props.collectionsMap != undefined)

    useEffect(() => {
        if(id != null && props.collectionsMap.has(id)) {
            isLoaded = true;
            setCollection(props.collectionsMap.get(id));
        }
    }, [props.collectionsMap]);

    return (
        <div>
            {
                !isLoaded && (collection != null) ? (
                    <CircularProgress  style={{marginTop: "30vh"}}/>
                ) : (
                    <HubInscription collection={collection} type={"image"} ownerAddress={props.ownerAddress} collectionAddress={""}/>
                )
            }
        </div>
    );
}

export default CollectionLoader;