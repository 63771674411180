import React, {useCallback, useState} from 'react';
import {AppBar, Container, Toolbar, Typography} from "@mui/material";
import WalletConnect from "./WalletConnect"
import {useBalance} from "../hooks/useBalance";
import {Link} from 'react-router-dom';
import ColoredButton from "./styled/ColoredButton";
import Grid from "@mui/material/Grid";
import {useOrdConnect} from "@ordzaar/ord-connect";
import "./WalletConnect.css"

type NavbarProps = {
    isHub: boolean;
}

const NavBar = ({isHub}: NavbarProps) => {
    const [balance, setBalance] = useState<number | undefined>(undefined);
    const { address, wallet } = useOrdConnect();

    const {
        getBalance,
        error: balanceError,
        loading: isLoadingBalance,
    } = useBalance();

    useCallback(async () => {
        const walletBalance = await getBalance();
        setBalance(walletBalance);
    }, [getBalance]);

    /* GET BALANcE
    useEffect(() => {
        getBalance()
            .then((balance) => {
                console.log("test");
                setBalance(balance / Math.pow(10, 8))
            })
            .catch(() => {
                console.log('error gettingbalance');
            })
    })
     */

    return (
        <AppBar component="nav" elevation={0} sx={{
            borderBottom: '1px solid #1c1326',
            background: '#101220',
            height: '72px',
        }}
        >
            <Toolbar >
                <Grid container spacing={0} style={{paddingTop: '12px', float: 'left'}}>
                    <Grid item xs={5} md={5}>
                        <Link to="/hub">
                            <ColoredButton style={{marginLeft: '0px', float: 'left'}}>
                                Home
                            </ColoredButton>
                        </Link>

                        {
                            (address.payments != null || address.ordinals != null) && (
                                <Link to="/profile">
                                    <ColoredButton style={{marginLeft: '12px', float: 'left'}}>
                                        Wallet
                                    </ColoredButton>
                                </Link>
                            )
                        }
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Container style={{marginTop: '-8px'}}>
                            <Link to="/hub">
                                <img src="/ordia_gif.gif" alt="logo" height={"54px"}/>
                            </Link>
                        </Container>
                    </Grid>
                    <Grid item xs={5} md={5}>
                        <div style={{float: 'right'}}>
                            <WalletConnect/>
                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default NavBar;