import React from 'react';
import {useState, useEffect} from "react";
import {useOrdConnect} from "@ordzaar/ord-connect";
import ProfileDisplay from "./ProfileDisplay";
import ColorButton from "./styled/ColoredButton";
import Container from "@mui/material/Container";
import axios from 'axios';

import {Collection} from "../types/Collection";
import NavBar from "./Navbar";
import Grid from "@mui/material/Grid";
import ColoredButton from "./styled/ColoredButton";
import LoginButton from "./LoginButton";
import UserProfile from "./UserProfile";
import {useDataContext} from "./DataContext";

interface ordHubProps{
    isHub: boolean;
    setCollectionMap: (map: Map<string, Collection>) => void;
}

const Profile = (props: ordHubProps) => {
    const { address, wallet } = useOrdConnect();

    const [isOrdinalFilter, setIsOrdinalFilter] = useState(true);
    const [isGlyphFilter, setIsGlyphFilter] = useState(true);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { data, setData } = useDataContext();

    useEffect(() => {
        // Check if the user is authenticated
        const checkAuthStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/auth`, {
                    withCredentials: true, // Send cookies with the request
                });

                // If authenticated, set the state to true
                if (response.status === 200) {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                // If not authenticated, set the state to false
                setIsAuthenticated(false);
            }
        };

        checkAuthStatus();
    }, []);

    return (
        <div style={{paddingTop: '24px'}}>
            <NavBar isHub={false}/>

            {
                isAuthenticated ? (
                    <Container style={{paddingTop: '5vh', maxWidth: '90vw'}}>
                        <UserProfile avatarOptions={data}/>
                    </Container>
                ) : (
                    <Container style={{paddingTop: '5vh', maxWidth: '90vw'}}>
                        <LoginButton/>
                    </Container>
                )
            }

            <Container style={{paddingTop: '10vh', maxWidth: '90vw'}}>

                <Grid container>
                    <Grid item xs={6}>
                        <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                            <div style={{fontFamily: 'FWD', fontSize: '24px', textAlign: 'left'}}>
                                Your Ordinals
                            </div>
                            <ColorButton style={{marginLeft: '24px', padding: '8px 48px'}}>
                                Holdings: {data?.size}
                            </ColorButton>
                        </div>
                    </Grid>
                    <Grid item xs={6} style={{float: 'right'}}>
                        <div style={{display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
                            <ColoredButton onClick={() => setIsOrdinalFilter(!isOrdinalFilter)} style={{marginRight: '24px', backgroundColor: '#000000', borderRadius: '12px', padding: '8px 48px'}}>
                                Ordinals
                            </ColoredButton>
                            <ColoredButton onClick={() => setIsGlyphFilter(!isGlyphFilter)} style={{marginRight: '24px', backgroundColor: '#000000', borderRadius: '12px', padding: '8px 48px'}}>
                                Glyphs
                            </ColoredButton>
                        </div>
                    </Grid>
                </Grid>

                <div style={{paddingTop: '24px'}}>
                    <ProfileDisplay setCollectionMap={props.setCollectionMap} isMyHubFilter={isGlyphFilter} isMyOrdinalFilter={isOrdinalFilter} ordinalAddress={address.ordinals}/>
                </div>
            </Container>
        </div>
    );
}

export default Profile;
