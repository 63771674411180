import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Container, Typography, MenuItem, FormControl, InputLabel, Select } from '@mui/material';

type mgmtConsoleProps = {
    address: string | null;
    fetchProposals: () => void;
    hideCreateProposal: () => void;
    inscription_id: string | undefined;
}

function CreateProposal(props: mgmtConsoleProps) {
    const categories = ["Locations", "Endeavors", "Challenges"];

    const [url, setUrl] = useState(process.env.REACT_APP_SERVER_URL);

    // Function to update the state when the user types in the input field
    const handleChange = (event: any) => {
        setUrl(event.target.value);
    };

    const [proposal, setProposal] = useState({
        title: '',
        description: '',
        // index: 0, TODO: Remove from schema or find use...
        category: '',
        inscription_address: props.inscription_id,
    });

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setProposal({ ...proposal, [name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            await axios.post(`${url}/proposal`, proposal).then(resp => {
                props.fetchProposals();
                props.hideCreateProposal();
            }).catch(err => {
                console.log("Err====> ", err)
            })
            // Reset form or handle next steps
        } catch (error) {
            console.error('Error creating proposal:', error);
        }
    };

    return (
        <Container maxWidth="sm"
                   style={{marginTop: '20px', borderRadius: '24px', padding: '12px'}} className="hoverClass">
            <Typography variant="h5" style={{fontFamily: 'FWD', marginBottom: '20px', color: 'white'}}>
                Create Proposal
            </Typography>
            <div>
                <div style={{padding: '4px'}}>dev url:</div>
                <input
                    type="text"
                    value={url}
                    onChange={handleChange}
                    style={{border: '1px solid gray', color: 'white', background: '#141414', width: '100%', padding: '12px'}}
                />
            </div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{padding: '4px'}}>Title:</div>
                        <TextField
                            fullWidth
                            name="title"
                            value={proposal.title}
                            onChange={handleInputChange}
                            required
                            style={{border: '1px solid gray', color: 'white', background: '#141414'}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{padding: '4px'}}>Description</div>
                        <TextField
                            fullWidth
                            name="description"
                            multiline
                            rows={4}
                            value={proposal.description}
                            onChange={handleInputChange}
                            required
                            style={{border: '1px solid gray', color: 'white', background: '#141414'}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                        <div style={{padding: '4px'}}>Category</div>
                        <Select
                            name="category"
                                value={proposal.category}
                                label="Category"
                                onChange={handleInputChange}
                                required
                                style={{border: '1px solid gray', color: 'white', background: '#141414'}}

                            >
                                {categories.map(category => (
                                    <MenuItem key={category} value={category}>
                                        {category}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Submit Proposal
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default CreateProposal;
