import React from 'react';
import { Card, CardContent, Typography, Box, Avatar, Link, SxProps, Theme, Container } from '@mui/material';

interface MessageCardProps {
    username: string;
    avatar: string;
    inscriptionNum: string;
    item: string;
    date: string; // This will be set to the formatted date
    time: string; // This will be set to the formatted time
    message: string;
    txLink: string;
}

const cardStyles: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'flex-start',
    fontFamily: 'FWD',
    p: 2,
    backgroundColor: '#1A1A1A',
    borderColor: '#00FFFF',
    mb: 2,
    border: '1px solid #2c2c2c',
    borderRadius: '.75rem',
    textAlign: 'left',
    cursor: 'pointer',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    paddingTop: '24px',
    '&:hover': {
        border: '1px solid #00BFFF',
        boxShadow: '0 0 4px #00BFFF',
    },
};

// Helper function to convert UNIX timestamp to readable date and time
const formatTimestamp = (timestamp: string) => {
    const date = new Date(parseInt(timestamp) * 1000); // Convert to milliseconds
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return { date: formattedDate, time: formattedTime };
};

const OrdiaGlyphDisplay: React.FC<MessageCardProps> = ({ username, avatar, inscriptionNum, item, date, time, message, txLink }) => {
    const { date: formattedDate, time: formattedTime } = formatTimestamp(time); // Format the timestamp

    const parseUsername = (userName: string) => {
        if (userName.length > 12) {
            return userName.substring(0, 4) + "..." + userName.substring(userName.length - 4, userName.length);
        }
    }

    return (
        <Card variant="outlined" sx={cardStyles}>
            <Avatar alt={username} src={avatar} sx={{ width: 56, height: 56 }} />
            <Container sx={{ flex: 1 }}>
                <Container sx={{ display: 'flex', alignItems: 'center'}}>
                    <Typography variant="body2" sx={{ color: '#FFFFFF', marginLeft: '-12px', mr: 1, fontFamily: 'FWD', fontSize: '12px' }}>
                        {parseUsername(username)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#AAAAAA', mr: 1, fontFamily: 'FWD', fontSize: '12px' }}>
                        to
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#00FF00', fontFamily: 'FWD', fontSize: '18px' }}>
                        {item} #{inscriptionNum}
                    </Typography>
                </Container>

                <CardContent>
                    <Typography variant="body1" sx={{ color: '#CCCCCC', fontFamily: 'FWD' }}>
                        {message}
                    </Typography>
                </CardContent>

                <Container sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                    <Typography sx={{ color: '#FFFFFF', fontSize: '12px', fontFamily: 'FWD' }}>
                        {formattedDate} @ {formattedTime}
                    </Typography>
                    &nbsp;&nbsp;
                    <Link href={txLink} sx={{ color: '#FFFFFF', textDecoration: 'underline', fontSize: '0.9rem' }} target="_blank">
                        VIEW TX
                    </Link>
                </Container>
            </Container>
        </Card>
    );
};

export default OrdiaGlyphDisplay;
