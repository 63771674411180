import React, {useEffect, useState} from 'react';
import Container from "@mui/material/Container";
import NavBar from "./Navbar";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import InscriptionContent from "./InscriptionContent";

type InscriptionResponse = {
    "limit": number,
    "offset": number,
    "total": number,
    "results": Inscription[]
}

type Inscription = {
    "id": string,
    "content_type": string,
    "contentURL": string,
}

const Inscriptions = () => {
    const [inscriptions, setInscriptions] = useState<Inscription[]>([]);

    useEffect(() => {
        fetch(`https://api.hiro.so/ordinals/v1/inscriptions?mime_type=image%2Fpng`)
            .then((response) => response.json())
            .then((results: InscriptionResponse) => setInscriptions(results.results.slice(0,24)))
            .catch((err) => {
                console.log("error: ", err);
            })
    }, []);

    return (
        <div style={{paddingTop: '10vh'}}>
            <NavBar isHub={false}/>

            <Container disableGutters={false} maxWidth={false} style={{textAlign: 'center', maxWidth: '1152px', overflow: 'scroll'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <div style={{fontFamily: 'FWD', width: '100%', fontSize: '36px', textAlign: 'left', justifyContent: 'center', padding: '.5rem'}}>
                            Newest Inscriptions
                            <Tooltip title={'Explore the latest ordinal inscriptions on Bitcoin.'}>
                                <InfoOutlinedIcon/>
                            </Tooltip>
                        </div>
                    </Grid>
                </Grid>

                <br/>

                <Grid container spacing={1}>
                    {
                        inscriptions.map(inscription => (
                            <Grid item xs={2} md={2} key={inscription.id}>
                                <InscriptionContent inscriptionId={inscription.id} width={"100%"} height={"15vh"} type={inscription.content_type} imageURL={undefined}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </div>
    );
}

export default Inscriptions;
