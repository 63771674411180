import * as React from 'react';
import { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import NavBar from "./Navbar";
import Container from "@mui/material/Container";
import { Avatar, styled } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import GlyphInscribe from "./GlyphInscribe";
import { MagicEdenCollectionResponse } from "../types/MagicEden";
import { useOrdConnect } from "@ordzaar/ord-connect";
import OrdiaGlyphDisplay from "./OrdiaGlyphDisplay";
import { GlyphData, parseGlyphData } from "./OrdiaGlyphUtils";

interface TransactionOutput {
    script_type: string;
    data_hex?: string;
    value?: number;
}

interface Transaction {
    hash: string;
    outputs: TransactionOutput[];
}

const CollectionBanner = styled(Container)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#000000',
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    border: '1px solid #2c2c2c',
    borderRadius: '.75rem',
    textAlign: 'left',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    paddingBottom: '24px',
}));

const GlyphWall = () => {
    const [glyphData, setGlyphData] = useState<GlyphData[]>([]);
    const { address, wallet } = useOrdConnect();
    const blockCypherToken = '05ec1993720c4b3ba516ad41c107309c';

    const [collectionId, setCollectionId] = useState<string>("");
    const [collection, setCollection] = useState<MagicEdenCollectionResponse>();

    useEffect(() => {
        const storedCollectionId = localStorage.getItem('selectedCollectionId');
        if (storedCollectionId) {
            setCollectionId(storedCollectionId);
        }
    }, []);

    useEffect(() => {
        if (collectionId) {
            fetch(`https://ordia.xyz:444/api/v2/ord/btc/collections/${collectionId}`, {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Basic ${process.env.REACT_APP_MAGIC_EDEN_API}`,
                    'Content-Type': 'application/json',
                }),
            })
                .then((res) => res.json())
                .then((response: MagicEdenCollectionResponse) => {
                    setCollection(response);
                })
                .catch((err) => {
                    console.log("Error fetching collection:", err);
                });
        }
    }, [collectionId]);

    useEffect(() => {
        const fetchOpReturnData = async (address: string | null) => {
            if (!address) return;
            try {
                const response = await fetch(`https://api.blockcypher.com/v1/btc/main/addrs/${address}/full?token=${blockCypherToken}`);
                const data = await response.json();

                const glyphList: GlyphData[] = data.txs
                    .map((tx: Transaction) => {
                        const opReturnOutput = tx.outputs.find(output => output.script_type === 'null-data');
                        if (opReturnOutput && opReturnOutput.data_hex) {
                            return parseGlyphData(opReturnOutput.data_hex, tx.hash);
                        }
                        return null;
                    })
                    .filter(Boolean) as GlyphData[];

                glyphList.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));

                setGlyphData(glyphList);
            } catch (error) {
                console.error('Error fetching OP_RETURN data:', error);
            }
        };

        fetchOpReturnData(address.payments);
    }, [address, wallet]);

    return (
        <div style={{ paddingTop: '48px' }}>
            <NavBar isHub={false} />
            <Container disableGutters={true} maxWidth={false} style={{ borderBottom: '1px solid #000124', paddingLeft: '12px', maxWidth: '1152px' }}>
                <Grid container spacing={4}>
                    <Grid item sm={12} md={12}>
                        <CollectionBanner style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                            <Grid container>
                                <Grid item sm={2} md={2}>
                                    <Avatar alt={collection?.name} src={collection?.imageURI}
                                            sx={{ width: '120px', height: '120px', mr: 2 }} />
                                </Grid>
                                <Grid item sm={10} md={10}>
                                    <div style={{ float: 'left', position: 'relative', top: '35%', fontSize: '24px' }}>
                                        {collection?.name}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: '24px' }}>
                                <Grid item sm={6} md={6} style={{ padding: '24px', background: '#161616', borderRadius: '12px', height: '100%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} md={12}>
                                            <span style={{ fontSize: '1.5rem' }}>Glyph</span>
                                        </Grid>
                                        <Grid item sm={12} md={12}>
                                            <GlyphInscribe />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} md={6} style={{ padding: '24px', paddingTop: '0px' }}>
                                    <div style={{ paddingBottom: '24px' }}>
                                        <span style={{ fontSize: '1.5rem' }}>My Glyphs</span>
                                    </div>
                                    <Grid container spacing={4}>
                                        <Grid item sm={12} md={12}>
                                            {glyphData.length > 0 ? (
                                                glyphData.map((glyph, index) => (
                                                    <OrdiaGlyphDisplay
                                                        key={index}
                                                        username={glyph.fromAddress}
                                                        avatar={"./4.png"}
                                                        inscriptionNum={"000"}
                                                        item={glyph.toAddress}
                                                        date={"some time"}
                                                        time={glyph.timestamp}
                                                        message={glyph.message}
                                                        txLink={`https://mempool.space/tx/${glyph.id}`}
                                                    />
                                                ))
                                            ) : (
                                                <Grid container>
                                                    No inscriptions found for user
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CollectionBanner>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default GlyphWall;
