import React, {useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import {Box, Container} from '@mui/material';
import Profile from "./components/Profile";
import {useOrdConnect} from "@ordzaar/ord-connect";
import {Collection} from "./types/Collection";
import CollectionLoader from "./components/CollectionLoader";
import Home from "./components/Home";
import Community from "./components/OrdiaHomePage";
import OrdCollection from "./components/OrdCollection";
import Inscriptions from "./components/Inscriptions";
import {DataProvider} from "./components/DataContext";
import ReactGA from 'react-ga4';
import VerifyWallet from "./components/VerifyWallet";
import GlyphWall from "./components/GlyphWall";

const AppJs = () => {
  const [isHub, setIsHub] = useState(true);
  const { address } = useOrdConnect();

  ReactGA.initialize("G-EGRHFW2GK4")

  const [collectionMap, setCollectionMapState] = useState<Map<string,Collection>>(new Map());
  const setCollectionMap = (map: Map<string, Collection>) => {
    setCollectionMapState(map);
  }

  const containerStyle = {
    borderRadius: 2,
    p: 0,
    textAlign: 'center',
    color: '#f8f7f8',
    mt: 4,
    mb: 4
  }

  return (
      <DataProvider>
        <Container disableGutters={true} maxWidth={false}>
          {/*<Container disableGutters={true} maxWidth={false} style={{backgroundImage: `url(./Background.png)`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>*/}
          <Box component="div" sx={containerStyle}>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/hub" element={<Community/>} />
              <Route path="/verify" element={<VerifyWallet/>} />
              <Route path="/glyph" element={<GlyphWall/>} />
              <Route path="/inscriptions" element={<Inscriptions/>} />
              <Route path="/collection/:id" element={<OrdCollection/>} />
              <Route path="/profile" element={<Profile setCollectionMap={setCollectionMap} isHub={isHub}/>} />
              <Route path="/inscription/:id" element={<CollectionLoader collectionsMap={collectionMap} type={"image"} ownerAddress={address.ordinals} collectionAddress={""}/>} />
            </Routes>
          </Box>
        </Container>
      </DataProvider>
  );
}

export default AppJs;
