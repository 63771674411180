import {  OrdConnectKit } from "@ordzaar/ord-connect";

const WalletConnect = () => {
    return (
        <div style={{padding: 'none'}}>
            <OrdConnectKit onViewProfile={() => console.log("View profile clicked")} />
        </div>
    );
}

export default WalletConnect;