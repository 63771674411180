import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';

import Grid from "@mui/material/Grid";
import {Container, styled} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import HubProposal from "./HubProposal";
import InscriptionContent from "./InscriptionContent";
import {useNavigate} from "react-router-dom";

import {Collection} from "../types/Collection";
import ButtonBase from "@mui/material/ButtonBase";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CollectionDetail from "./CollectionDetail";
import NavBar from "./Navbar";
import {useDataContext} from "./DataContext";
import Visibility from '@mui/icons-material/Visibility';

const HubCollectionStyledCard = styled(Container)<ButtonProps>(({theme}) => ({
    color: theme.palette.getContrastText('#000124'),

    //Gray
    backgroundColor: '#141414',
    marginTop: '4px',
    fontSize: '1rem',
    border: '1px solid #1c1326',
    borderRadius: '.75rem',
    padding: '.5rem .5rem ',
    textAlign: 'left',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',

    '&:hover': {
        border: '1px solid #00BFFF',
        boxShadow: '0 0 8px #00BFFF',
    },
}));

type hubCollectionProps = {
    ownerAddress: string | null;
    collectionAddress: string | null;
    type: string;
    collection: Collection | undefined;
}

type ProposalType = {
    id: number;
    title: string;
    description: string;
    index: number;
    category: string;
    inscription_address: string;
    created_at: string;
    last_updated: string;
}

const HubInscription = (props: hubCollectionProps) => {
    const [inscription, setInscription] = useState<Collection>();

    const address = props.ownerAddress;
    const [proposals, setProposals] = useState<ProposalType[]>([]);

    // TODO: Loading and error handling
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    // Inscription ID Path Param
    const {id} = useParams()

    // TODO: Verify isOwner Logic
    const isOwner = false

    const {data} = useDataContext();

    let navigate = useNavigate();
    const routeChange = () => {
        if (inscription && data.get(inscription.id)) {
            const collection = data.get(inscription.id)?.collection.symbol;
            if (collection !== undefined) {
                let path = `/collection/${collection}`;
                navigate(path);
            }
        }
    }

    useEffect(() => {
        fetch(`https://api.hiro.so/ordinals/v1/inscriptions/${id}`)
            .then((response) => response.json())
            .then((result: Collection) => setInscription(result))
            .catch((err) => {
                console.log("error: ", err);
            })
    }, []);

    const fetchProposals = useCallback(async () => {
        if (!address) {
            setProposals([]);
            return;
        }
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/proposals/${(data && inscription) && data.get(inscription.id)?.collection.name}`);
            setProposals(response.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }, [address, data]);

    useEffect(() => {
        fetchProposals();
    }, [fetchProposals]);


    const hideDetails = () => {
        setShowDetails(!showDetails)
    }

    let isClaimable = false
    if (data && inscription && data.get(inscription.id)?.collection && data.get(inscription.id)?.collection.symbol === "bitcoin_spacemen") {
        isClaimable = true
    }

    return (
        <Container>
            <NavBar isHub={false}/>

            <Grid container spacing={2} style={{marginTop: '10vh', fontFamily: 'FWD'}}>
                {/* Left Column */}
                <Grid item xs={12} md={5.75}>
                    <div style={{border: '1px solid #2c2c2c', borderRadius: '.75rem', background: '#08050a'}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12}>
                                {
                                    id ? (
                                        <InscriptionContent inscriptionId={id} width={"45vw"} height={"45vw"}
                                                            type={inscription?.content_type} imageURL={data.get(id)?.contentPreviewURI}/>
                                    ) : <div></div>
                                }
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{
                        border: '1px solid #2c2c2c',
                        borderRadius: '.75rem',
                        background: '#08050a',
                        padding: '12px',
                        marginTop: '12px',
                    }}>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <div style={{
                                    fontSize: '1rem',
                                    padding: '18px 6px',
                                }}>#{inscription?.number}</div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <div style={{
                                    fontSize: '1rem',
                                    padding: '18px 6px',
                                }}>{inscription?.content_type}</div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <div style={{
                                    fontSize: '1rem',
                                    padding: '18px 6px',
                                }}>{inscription?.sat_rarity}</div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={0} md={.2}></Grid>

                {/* Right Column */}
                <Grid item xs={12} md={5.75}>
                    <div style={{
                        border: '1px solid #2c2c2c',
                        borderRadius: '.75rem',
                        background: '#000000',
                        padding: '.5rem',
                        width: '100%'
                    }} className="hoverClass">
                        <Grid container>
                            <Grid item xs={12} md={12} style={{textAlign: 'left', fontSize: '1.25rem'}}>
                                <div style={{padding: '.75rem ', fontWeight: '400'}}>Inscription
                                    #{inscription?.number}</div>
                            </Grid>
                            <Grid item xs={12} md={12} style={{textAlign: 'left', fontSize: '1rem'}}>
                                <div style={{
                                    paddingLeft: '.75rem',
                                    fontWeight: '200',
                                }}>
                                    <span style={{
                                        float: 'left',
                                        paddingTop: '12px'
                                    }}>{(data && inscription && data.get(inscription.id)?.collection) && data.get(inscription.id)?.collection.name}</span>
                                    <span style={{float: 'left', paddingTop: '10px', paddingLeft: '14px'}}>
                                        <Visibility style={{color: '#FFFFFF'}} onClick={() => routeChange()}/>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{
                        marginTop: '8px',
                        border: '1px solid #2c2c2c',
                        borderRadius: '.75rem',
                        background: '#000000',
                        padding: '.5rem',
                        width: '100%'
                    }} className="hoverClass">
                        <Grid item xs={12} md={12}>
                            <Grid item xs={12} md={12}>
                                <Grid container>
                                    <Grid item xs={12} md={10} style={{padding: '8px 8px'}}>
                                        <div style={{
                                            fontSize: '1.25rem',
                                            padding: '1rem',
                                            textAlign: 'left'
                                        }}>Details
                                        </div>
                                    </Grid>

                                    <Grid item xs={1} md={2} style={{padding: '24px', fontSize: '1em'}}>
                                        <ButtonBase onClick={hideDetails}>
                                            {!showDetails ? (<ExpandMoreIcon/>) : (<ExpandLessIcon/>)}
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                showDetails ? (
                                    <CollectionDetail collection={inscription}/>
                                ) : (
                                    <Grid container>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </div>

                    <div style={{
                        marginTop: '8px',
                        border: '1px solid #2c2c2c',
                        borderRadius: '.75rem',
                        background: '#000000',
                        padding: '.5rem',
                        width: '100%'
                    }} className="hoverClass">
                        <Grid item xs={12} md={12}>
                            <Grid container>
                                <Grid item xs={12} md={6} style={{textAlign: 'left', fontSize: '1.25rem'}}>
                                    <div style={{padding: '.75rem ', fontWeight: '400'}}>Proposals</div>
                                </Grid>
                            </Grid>
                            {
                                proposals ? proposals.map((proposal: ProposalType, index) => (
                                    <HubCollectionStyledCard key={index}>
                                        <HubProposal
                                            key={proposal.id}
                                            title={proposal.title}
                                            fetchProposals={fetchProposals}
                                            id={proposal.id}
                                            isOwner={isOwner}
                                            description={proposal.description}
                                            iconStyle={proposal.category}
                                            userAddress={address}
                                        />
                                    </HubCollectionStyledCard>
                                )) : (
                                    <div style={{fontWeight: '100'}}>No Active Proposals</div>
                                )
                            }
                        </Grid>
                    </div>

                    <div style={{
                        marginTop: '8px',
                        border: '1px solid #2c2c2c',
                        borderRadius: '.75rem',
                        background: '#000000',
                        padding: '.5rem',
                        width: '100%',
                    }} className="hoverClass">
                        <Grid container>
                            <Grid item xs={12} md={12} style={{textAlign: 'center', fontSize: '.75rem', color: 'grey'}}>
                                <div style={{padding: '.75rem ', fontWeight: '100'}}>
                                    More Features Coming Soon
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HubInscription;