// DataContext.tsx
import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { useOrdConnect } from "@ordzaar/ord-connect";
import {MagicEdenTokens, MagicEdenApiTokenResp, HiroContentResp} from "../types/MagicEden"

 interface DataContextType {
    data: Map<string, MagicEdenTokens>;
    setData: React.Dispatch<React.SetStateAction<Map<string, MagicEdenTokens>>>;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

const DataProvider = ({ children }: { children: ReactNode }) => {
    const [data, setData] = useState<Map<string, MagicEdenTokens>>(new Map());
    const { address, wallet } = useOrdConnect();

    useEffect(() => {
        if(address && address.ordinals) {
            fetch(`https://api.hiro.so/ordinals/v1/inscriptions?address=${address.ordinals}`)
                .then((response) => response.json())
                .then((data) => data.results)
                .then((results: HiroContentResp[]) => {
                    const inscriptionList = results.filter(item => item.content_type.startsWith('image/')).map((obj) => obj.id)
                    return fetch(`https://ordia.xyz:444/api/v2/ord/btc/tokens?tokenIds=${inscriptionList.join(",")}`,{
                        method: 'GET',
                        headers: new Headers({
                            'Authorization': `Basic ${process.env.REACT_APP_MAGIC_EDEN_API}`,
                            'Content-Type': 'application/json'
                        }),
                    })
                })
                .then((response) => response.json())
                .then((res: MagicEdenApiTokenResp) => {
                    const tokenMap = new Map(res.tokens.map(item => [item.id, item]));
                    setData(tokenMap)

                    return
                })
                .catch((err) => {
                    console.log("error: ", err);
                })
        }
    }, [address]);

    return (
        <DataContext.Provider value={{ data, setData }}>
            {children}
        </DataContext.Provider>
    );
};

const useDataContext = (): DataContextType => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

export { DataProvider, useDataContext };
