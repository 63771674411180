import {styled} from "@mui/material";
import Container from "@mui/material/Container";
import {ButtonProps} from "@mui/material/Button";
import {CSSProperties} from "react";

export type MagicEdenInscription = {
    "symbol": string,
    "name": string,
    "imageURI": string,
    "inscriptionIcon": string,
    "collectionInscriptionId": null,
    "description": string,
    "twitterLink": string,
    "discordLink": string,
    "websiteLink": string,
    "totalVolume": string,
    "sales": string,
    "salesInMempool": string,
    "floorPrice": string,
    "owners": string,
    "supply": number,
    "volume": string
}
export const CollectionBanner = styled(Container)<ButtonProps>(({theme}) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#000000',
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    border: '1px solid #2c2c2c',
    borderRadius: '.75rem',
    textAlign: 'left',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    padding: '24px',
    //'&:hover': {
    //    border: '1px solid #00BFFF',
    //    boxShadow: '0 0 4px #00BFFF',
    //},
}));

export const textStyle: CSSProperties = {
    fontFamily: 'FWD',
    width: '100%',
    fontSize: '24px',
    textAlign: 'left',
    float: 'left',
    justifyContent: 'left',
    padding: '12px 12px'
}

export const textSubStyle: CSSProperties = {
    fontFamily: 'FWD',
    width: '100%',
    fontSize: '12px',
    textAlign: 'left',
    float: 'left',
    justifyContent: 'left',
    padding: '12px 12px',
    textDecoration: 'underline',
}

export const textStyleBlue: CSSProperties = {
    fontFamily: 'FWD',
    width: '100%',
    fontSize: '1rem',
    textAlign: 'left',
    justifyContent: 'left',
    padding: '12px 12px',
    color: '#00BFFF'
}

export const textStyleGold: CSSProperties = {
    fontFamily: 'FWD',
    width: '100%',
    fontSize: '1rem',
    textAlign: 'left',
    justifyContent: 'left',
    padding: '0px',
    color: '#FF9900'
}

export const textStyleBlueHeading: CSSProperties = {
    fontFamily: 'FWD',
    width: '100%',
    fontSize: '2rem',
    textAlign: 'left',
    justifyContent: 'left',
    padding: '12px 12px',
    color: '#00BFFF'
}

export const textStyleWhite: CSSProperties = {
    fontFamily: 'FWD',
    width: '100%',
    fontSize: '1rem',
    textAlign: 'left',
    justifyContent: 'left',
    padding: '12px 12px',
    color: '#FFFFFF'
}

export const textStyleWhiteHeading: CSSProperties = {
    fontFamily: 'FWD',
    width: '100%',
    fontSize: '1.25rem',
    textAlign: 'left',
    justifyContent: 'left',
    padding: '12px 12px',
    color: '#FFFFFF'
}

export const textStyleWhiteHeadingLarge: CSSProperties = {
    fontFamily: 'FWD',
    width: '100%',
    fontSize: '2.2rem',
    textAlign: 'left',
    justifyContent: 'left',
    padding: '0px',
    paddingBottom: '12px',
    color: '#FFFFFF'
}