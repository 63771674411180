import React, { useEffect, useState } from 'react';
import { Avatar, Container, Typography, Button, Modal, Grid, styled } from '@mui/material';
import { useOrdConnect } from '@ordzaar/ord-connect';
import axios from 'axios';
import {MagicEdenTokens} from "../types/MagicEden";

const ProfileContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const ProfileTextContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

const Username = styled(Typography)({
    fontSize: '1.25rem',
    fontWeight: 'bold',
});

const Description = styled(Typography)({
    fontSize: '0.875rem',
    color: '#ccc',
});

const AvatarOption = styled(Avatar)({
    cursor: 'pointer',
    width: 80,
    height: 80,
    margin: '8px',
    '&:hover': {
        border: '2px solid #00BFFF',
    },
});

interface UserProfileProps {
    avatarOptions: Map<string, MagicEdenTokens>
}

const UserProfile = (props: UserProfileProps) => {
    const { address } = useOrdConnect();
    const [username, setUsername] = useState('anon69');
    const [profilePic, setProfilePic] = useState('/default-avatar.png');
    const [description, setDescription] = useState('Ordinal trader, bitcoin maxi. Crypto since 1897.');
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Fetch user profile if logged in
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get('/api/user/profile', { withCredentials: true });
                if (response.data) {
                    setUsername(response.data.username || 'anon69');
                    setProfilePic(response.data.profilePic || '/default-avatar.png');
                    setDescription(response.data.description || 'Ordinal trader, bitcoin maxi. Crypto since 1897.');
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, []);

    // Handle avatar selection
    const handleAvatarSelection = (avatarURI: string) => {
        setProfilePic(avatarURI); // Update the profile pic locally
        setIsModalOpen(false);

        // Optionally, update on the server
        axios.post('/api/user/profile', { profilePic: avatarURI }, { withCredentials: true })
            .then(() => console.log("Profile picture updated successfully"))
            .catch(err => console.error("Failed to update profile picture:", err));
    };

    return (
        <ProfileContainer maxWidth="md">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={username} src={profilePic} sx={{ width: 80, height: 80, mr: 2 }} />
                <ProfileTextContainer>
                    <Username>@{username}</Username>
                    <Description>{description}</Description>
                </ProfileTextContainer>
            </div>
            <div>
                <Button variant="contained" style={{ marginRight: '8px' }} onClick={() => setIsModalOpen(true)}>
                    Change Avatar
                </Button>
                <Button variant="contained" color="secondary">X</Button>
            </div>

            {/* Modal for avatar selection */}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Container maxWidth="sm" style={{ padding: '16px', backgroundColor: '#fff', marginTop: '10%', borderRadius: '8px' }}>
                    <Typography variant="h6" style={{ marginBottom: '16px' }}>Select Your Avatar</Typography>
                    <Grid container spacing={2} justifyContent="center">
                        {Array.from(props.avatarOptions.values()).map((token) => (
                            <Grid item key={token.id}>
                                <AvatarOption
                                    alt={token.name}
                                    src={token.contentPreviewURI}
                                    onClick={() => handleAvatarSelection(token.contentPreviewURI)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Modal>
        </ProfileContainer>
    );
};

export default UserProfile;
