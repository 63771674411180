import React, { useState, useEffect } from "react";
import { useOrdConnect, useSignMessage } from "@ordzaar/ord-connect";
import axios from "axios";
import { Button, CircularProgress, Alert } from "@mui/material";
import ColoredButton from "./styled/ColoredButton";

const LoginButton = () => {
    const { network, publicKey, format } = useOrdConnect();
    const { address, wallet } = useOrdConnect();
    const { signMsg } = useSignMessage();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (!wallet) {
            setError("Wallet not connected");
        }
    }, [wallet]);

    const handleLogin = async () => {
        if (!address?.payments || !publicKey?.payments) {
            setError("Unable to retrieve wallet address or public key.");
            return;
        }

        setLoading(true);
        setError("");
        setSuccess(false);

        try {
            const currentDate = new Date().toLocaleDateString();
            const message = `Logging in to Ordia\n${currentDate}`;

            // Sign the message
            const signedMessage = await signMsg(address.payments, message);

            // Send the signed message to the backend to create a session
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, {
                wallet_address: address.payments,
                signed_message: signedMessage,
                public_key: publicKey.payments,
            }, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true // Ensures cookies are included
            });

            if (response.status === 200) {
                setSuccess(true);
            } else {
                setError(response.data || "Unexpected response from server.");
            }
        } catch (err) {
            setError("Login failed. Please try again later.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <ColoredButton
                onClick={handleLogin}
                disabled={loading}
                variant="contained"
                color="primary"
            >
                {loading ? <CircularProgress size={24} /> : "Login with Ordia"}
            </ColoredButton>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">Login successful!</Alert>}
        </div>
    );
};

export default LoginButton;
