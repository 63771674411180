import React, { useState, useCallback, CSSProperties } from 'react';
import {useSign} from "@ordzaar/ord-connect";
import * as bitcoin from 'bitcoinjs-lib';
import {useOrdConnect} from "@ordzaar/ord-connect";
import ColorButton from "./styled/ColoredButton";

const InscribeMessage = () => {
    const [message, setMessage] = useState('');
    const blockCypherToken = '05ec1993720c4b3ba516ad41c107309c'; // BlockCypher API Token

    const { address, wallet } = useOrdConnect();
    const { sign, error: signMessageError } = useSign();
    const feeRate = 25;
    const dustThreshold = 546; // Typical dust threshold for P2WPKH outputs

    type Output = {
        address?: string;
        value: number;
        script?: Buffer; // If you're dealing with OP_RETURN or other scripts
    };

    const calculateTransactionSize = (inputs: any, outputs: Output[], isSegWit: boolean = true): number => {
        const baseTxSize = 10; // Version (4 bytes) + Locktime (4 bytes) + other overhead (2 bytes)
        const segwitFlagSize = isSegWit ? 2 : 0; // 2 bytes for segwit flag

        const inputBaseSize = 32 + 4 + 1 + 4; // Txid (32 bytes) + vout (4 bytes) + scriptSig length (1 byte) + sequence (4 bytes)
        const witnessSize = isSegWit ? 107 : 0; // SegWit witness data (107 bytes is typical for P2WPKH, varies for other types)

        const inputSize = (inputBaseSize + witnessSize) * inputs.length;

        const outputBaseSize = 8 + 1 + 25; // Amount (8 bytes) + scriptPubKey length (1 byte) + scriptPubKey (25 bytes for P2PKH)
        const outputSize = outputBaseSize * outputs.length;

        // Explicitly typing 'sum' as a number
        const opReturnSize = outputs.filter((output: Output) => output.script).reduce((sum: number, output: Output) => {
            return sum + 1 + (output.script?.length || 0); // 1 byte for OP_RETURN + script length
        }, 0);

        return baseTxSize + segwitFlagSize + inputSize + outputSize + opReturnSize;
    };

    /**
     * Fetch UTXOs for a given address from Blockstream API.
     */
    async function fetchUTXOs(address: string) {
        const response = await fetch(`https://blockstream.info/api/address/${address}/utxo`); // Use mainnet URL for mainnet
        const utxos = await response.json();
        console.log("utxos: ", utxos)
        return utxos;
    }

    /**
     * Fetch the raw transaction hex from Blockstream API.
     */
    async function fetchRawTransaction(txid: string) {
        const response = await fetch(`https://blockstream.info/api/tx/${txid}/hex`); // Use mainnet URL for mainnet
        const rawTx = await response.text();
        return rawTx;
    }

    function getWitnessUtxoFromRawTransaction(rawTx: string, voutIndex: number) {
        const tx = bitcoin.Transaction.fromHex(rawTx);
        const output = tx.outs[voutIndex];

        return {
            value: output.value, // Value in satoshis
            scriptPubKey: output.script.toString('hex'), // Hex of the scriptPubKey
        };
    }

    /**
     * Build UTXO input for PSBT from address and amount.
     * @param {string} address - Bitcoin address to fetch UTXOs from.
     * @param {number} amount - Amount (in satoshis) to spend from the UTXOs.
     * @returns {Promise<Object>} - A PSBT input object with witnessUtxo and input details.
     */
    async function buildUtxoInputForPsbt(address: string, amount: number) {
        const utxos = await fetchUTXOs(address);
        let total = 0;
        const inputs = [];

        for (const utxo of utxos) {
            if (total >= amount) break; // Stop when we have enough satoshis

            // Fetch raw transaction for each UTXO
            const rawTx = await fetchRawTransaction(utxo.txid);

            // Get witness UTXO information from raw transaction
            const witnessUtxo = getWitnessUtxoFromRawTransaction(rawTx, utxo.vout);

            // Build the PSBT input for this UTXO
            inputs.push({
                hash: utxo.txid, // Transaction ID of the UTXO
                index: utxo.vout, // Output index of the UTXO
                witnessUtxo: {
                    value: witnessUtxo.value, // Value in satoshis
                    script: Buffer.from(witnessUtxo.scriptPubKey, 'hex'), // scriptPubKey in buffer format
                },
            });

            total += witnessUtxo.value; // Add this UTXO's value to the total
        }

        if (total < amount) {
            throw new Error('Not enough funds in UTXOs for the specified amount');
        }

        return inputs;
    }
    const createUnsignedPSBTBase64 = async (entryText: string, fromAddress: string, toAddressOrRealm: string) => {
        // Define the network (Bitcoin Mainnet)
        const network = bitcoin.networks.bitcoin;

        // Fetch UTXOs and inputs
        const inputs = await buildUtxoInputForPsbt(fromAddress, 1200); // Modify fromAddress

        // Define the content type for the inscription
        // const contentType = Buffer.from("text/plain;charset=utf-8", 'utf8');

        const recipientAddress = address.payments;
        if(!recipientAddress) {
            return;
        }

        const outputs = [
            {
                address: recipientAddress, // Recipient address (could be a fee address or change)
                value: 1000, // Value in satoshis
            },
        ];

        const txSize = calculateTransactionSize(inputs, outputs);
        let fee = Math.ceil(txSize * feeRate); // Ensure the fee is rounded up

        // Adjust the change output
        const totalInputValue = inputs.reduce((sum, input) => sum + input.witnessUtxo.value, 0);
        const totalOutputValue = outputs.reduce((sum, output) => sum + output.value, 0);
        let changeValue = totalInputValue - totalOutputValue - fee;

        if (changeValue >= dustThreshold) {
            outputs.push({
                address: fromAddress, // Send change to the sender's address
                value: changeValue,
            });
        } else {
            fee += changeValue; // Add remaining change to the fee
            changeValue = 0; // No change output
        }

        // Initialize a new PSBT
        const psbt = new bitcoin.Psbt({ network });

        // Add inputs to the PSBT
        inputs.forEach(input => {
            psbt.addInput({
                hash: input.hash,
                index: input.index,
                witnessUtxo: input.witnessUtxo,
            });
        });

        // New protocol inscription (OP_RETURN "GLYPH" <version> <from_address> <to_address|realm_id> <message> <timestamp>)
        const version = "xx";
        const timestamp = Math.floor(Date.now() / 1000); // Unix timestamp

        // Create the OP_RETURN inscription script for the new protocol standard
        const inscriptionScript = bitcoin.script.compile([
            bitcoin.opcodes.OP_RETURN,                      // OP_RETURN to mark the data
            Buffer.from("GLYPH", 'utf8'),               // Protocol identifier
            Buffer.from(version, 'utf8'),                   // Version
            Buffer.from(fromAddress, 'utf8'),               // Sender's Bitcoin address
            Buffer.from(toAddressOrRealm, 'utf8'),          // Recipient's Bitcoin address or Realm ID
            Buffer.from(entryText, 'utf8'),                 // The message content
            Buffer.from(timestamp.toString(), 'utf8')       // The Unix timestamp
        ]);

        // Add OP_RETURN output to the PSBT
        psbt.addOutput({
            script: inscriptionScript,  // Custom protocol script
            value: 546,                 // Minimum sats for OP_RETURN output
        });

        // Add other outputs to the PSBT
        outputs.forEach(output => {
            psbt.addOutput({
                address: output.address,
                value: output.value,
            });
        });

        // Serialize the PSBT and encode it in Base64
        const psbtBase64 = psbt.toBase64();

        // Return the Base64 encoded PSBT
        return psbtBase64;
    };



    // This method will handle the message and create a transaction
    const handleInscribeGlyph = useCallback(async(entryText: string, walletFromAddress: string | null) => {
        if(!walletFromAddress) {
            alert('Please setup a wallet');
            return;
        }
        if (!entryText) {
            alert('Please enter a message');
            return;
        }

        // Example usage
        const unsignedPSBTBase64 = await createUnsignedPSBTBase64(entryText, walletFromAddress, "toABC");
        if (!unsignedPSBTBase64) {
            alert('Error creating psbt');
            return;
        }

        // Placeholder for the actual implementation to create the Bitcoin transaction
        if(address.ordinals) {
            const signed = await sign(
                walletFromAddress,
                unsignedPSBTBase64,
                {},
            ).then((res) => {
                // console.log("signed tx: ", res)
                pushRawTx(res.hex)
            }).then((res2) => {
                console.log("signed tx resp: ", res2)
            }).catch((err) => {
                console.log("err: ", err);
            });

            console.log("SIGNED: ", signed)
        }

        // Clear the input after the transaction
        setMessage('');
    }, []);

    // Define CSS styles explicitly
    const containerStyle: CSSProperties = {
        borderRadius: '10px',
        textAlign: 'center',
        fontFamily: 'FWD'
    };

    const textareaStyle: CSSProperties = {
        width: '100%',
        height: '100px',
        backgroundColor: '#1c1c1c',
        color: '#fff',
        border: 'none',
        borderRadius: '10px',
        padding: '10px',
        fontSize: '16px',
        marginBottom: '20px',
        outline: 'none',
        resize: 'none',
        fontFamily: 'FWD'
    };

    const buttonStyle: CSSProperties = {
        backgroundColor: '#1C1C1C',
        color: '#fff',
        padding: '8px 24px',
        border: 'none',
        borderRadius: '10px',
        fontSize: '16px',
        cursor: 'pointer',
        fontFamily: 'FWD',
        float: 'left',
        width: '50%',
    };

    const pushRawTx = async (signedTxHex: string) => {
        const response = await fetch(`https://api.blockcypher.com/v1/btc/main/txs/push?token=${blockCypherToken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ tx: signedTxHex })
        });

        const result = await response.json();
        console.log("pushRawTx: ", result);
    };

    return (
        <div style={containerStyle}>
          <textarea
              style={textareaStyle}
              placeholder="Type here..."
              value={message}
              onChange={(e) => {
                  const targetValue = e.currentTarget.value;
                  setMessage(targetValue)
                  }
              }
          />
            <ColorButton style={buttonStyle} onClick={() => handleInscribeGlyph(message, address.payments)}>
                Inscribe Glyph
            </ColorButton>
        </div>
    );
};

export default InscribeMessage;
