import React,  { useState, useEffect } from 'react';
import Container from "@mui/material/Container";
import ColoredButton from "./styled/ColoredButton";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const Home = () => {
    let navigate = useNavigate();
    const routeChange = () =>{
        let path = `hub`;
        navigate(path);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767); // Adjust the breakpoint as needed

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{paddingTop: '24px'}}>
            {
                !isMobile ? (
                    <Container disableGutters={true} maxWidth={false}
                               style={{paddingTop: '12px', paddingLeft: '12px'}}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25vh', height: '20vh'}}>
                            <img src="/ordia_gif.gif" alt="logo" height={"120"}/>
                        </div>
                        <div style={{fontSize: '36px'}}>
                            <ColoredButton onClick={routeChange}>
                                LAUNCH APP
                            </ColoredButton>
                        </div>
                    </Container>
                ) : (
                    <Container disableGutters={true} maxWidth={false}
                               style={{paddingTop: '12px', paddingLeft: '12px'}}>
                        <div style={{display: 'flex', fontFamily: 'FWD', alignItems: 'center', justifyContent: 'center', height: '40vh'}}>
                            Please view on desktop
                        </div>
                    </Container>
                )
            }
            <Footer />
        </div>
    );
}

export default Home;
