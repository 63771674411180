import React, {useEffect, useState} from 'react';
import Container from "@mui/material/Container";
import NavBar from "./Navbar";
import Grid from "@mui/material/Grid";
import TopCollectionDisplay from "./TopCollectionDisplay";
import {useOrdConnect} from "@ordzaar/ord-connect";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";
import ExplorerAssetCard from "./ExplorerAssetCard";
import LatestGlyphs from "./LatestGlyphs";
import {
    CollectionBanner,
    MagicEdenInscription,
    textStyle,
    textStyleBlue, textStyleBlueHeading, textStyleGold,
    textStyleWhite, textStyleWhiteHeading, textStyleWhiteHeadingLarge
} from "./OrdiaSharedComponents";

const Hub = () => {
    ReactGA.send({
        hitType: "pageView",
        page: '/hub',
        title: "Hub"
    })

    const [topCollections, setTopCollections] = useState<MagicEdenInscription[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const { address, wallet } = useOrdConnect();

    const scrollDown = () => {
        window.scrollBy({ top: window.innerHeight, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        fetch(`https://ordia.xyz:444/api/v2/ord/btc/popular_collections?window=1d`,
            {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Basic ${process.env.REACT_APP_MAGIC_EDEN_API}`,
                    'Content-Type': 'application/json'
                }),
            }
        )
            .then((response) => {
                if(!response.ok) {
                    console.log("handle error")
                }
                return response.json()
            })
            .then((results: MagicEdenInscription[]) => {
                setTopCollections(results)
                setIsLoaded(true)
            })
            .catch((err) => {
                console.log("error: ", err);
            })
    }, [address]);

    let navigate = useNavigate();
    const routeChange = (id: string) =>{
        let path = `/collection/${id}`;
        navigate(path);
    }

    const routeChangeOut = (link: string) =>{
        window.open(link, '_blank'); // Opens in a new tab

    }

    return (
        <div>
            <Container style={{textAlign: 'center', maxWidth: '1152px', overflow: 'scroll', marginTop: '10vh'}}>
                <NavBar isHub={true}/>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                        <CollectionBanner>
                            <div style={textStyleBlue}>
                                Welcome to Ordia, we are currently in Beta.
                            </div>

                            <div style={textStyleWhite}>
                                If you are seeing this, you are one of the few lucky users who get to try Ordia before
                                it’s public launch.
                            </div>
                        </CollectionBanner>
                    </Grid>

                    <div style={{width: '100%', textAlign: 'center'}}>
                        {
                            !isLoaded && (
                                <CircularProgress/>
                            )
                        }
                    </div>

                    <Grid item xs={12} md={12}>
                        <CollectionBanner>
                            <div style={textStyleWhiteHeadingLarge}>
                                Browse Hubs
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={3} md={3} className="img_wrap">
                                    <ExplorerAssetCard
                                        title="Bitcoin Spacemen"
                                        supply={1000}
                                        holders={"379"}
                                        imageSrc="./4.png"
                                        magicEdenCollectionName="bitcoin_spacemen"
                                        onClick={() => routeChange("bitcoin_spacemen")}
                                        onClickOut={() => routeChangeOut("https://ordia.xyz")}
                                    />
                                </Grid>
                                {
                                    topCollections && topCollections.length > 0 && topCollections.slice(0, 7).map((item, index) => (
                                        <Grid item xs={3} md={3} className="img_wrap">
                                            <ExplorerAssetCard
                                                title={item.name}
                                                supply={item.supply}
                                                holders={item.owners}
                                                imageSrc={item.imageURI}
                                                magicEdenCollectionName={item.symbol}
                                                onClick={() => routeChange(item.symbol)}
                                                onClickOut={() => routeChangeOut("https://ordia.xyz")}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </CollectionBanner>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <CollectionBanner>
                            <div style={{display: 'flex'}}>
                                <div style={textStyle}>
                                    <div style={textStyleWhiteHeadingLarge}>
                                        Introducing Glyphs
                                    </div>

                                    <div style={textStyleGold}>
                                        Expression with provenance
                                    </div>
                                </div>
                                <div style={{maxWidth: '50%'}}>
                                    <div style={textStyleWhiteHeading}>
                                        Write a message and correlate it with any pre existing inscription.
                                        <span style={{
                                            textDecoration: 'underline',
                                            fontSize: '12px',
                                            paddingLeft: '12px',
                                            color: '#FF9900'
                                        }}>
                                            Learn More
                                        </span>
                                    </div>
                                    <div style={textStyleWhite}>

                                    </div>
                                </div>
                            </div>
                        </CollectionBanner>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <CollectionBanner>
                            <div style={textStyleWhiteHeadingLarge}>
                                Recent Glyphs
                            </div>
                            <LatestGlyphs/>
                        </CollectionBanner>
                    </Grid>
                </Grid>


                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                }}>
                    <button onClick={scrollDown}
                            style={{fontSize: '24px', cursor: 'pointer', background: 'none', border: 'none'}}>
                        ️<KeyboardArrowDownIcon style={{color: 'white'}}/>
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default Hub;
