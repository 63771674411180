import React from 'react';
import {Grid, Card, CardContent, Avatar, styled} from '@mui/material';
import ColoredButton from "./styled/ColoredButton";
import Button, {ButtonProps} from "@mui/material/Button";

const ColorButton2 = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#000000',
    borderRadius: '12px',
    paddingTop: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
    textTransform: 'none',
    fontWeight: '800',
    cursor: 'pointer',
    boxShadow: '0 0 7px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    border: '1px solid #3b3b3b',
    width: '100%',

    fontFamily: 'FWD',

    '&:hover': {
        border: '2px solid #00BFFF',
        boxShadow: '0 0 4px #00BFFF',
    },
}));

// AssetCard Component
const ExplorerAssetCard: React.FC<{ title: string; supply: number; holders: string; imageSrc: string; magicEdenCollectionName: string; onClick: () => void; onClickOut: () => void }> = ({ title, supply, holders, imageSrc, onClick , onClickOut}) => {
    return (
        <Card
            sx={{
                backgroundColor: '#161616',
                borderRadius: '20px',
                fontFamily: 'FWD',
                boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
                textAlign: 'center',
            }}
            className="hoverClass2"
            onClick={onClick}
        >
            <CardContent>
                <div style={{fontFamily: 'FWD', color: 'white', fontSize: "1rem", paddingBottom: '12px', lineHeight: '24px', textAlign: 'center', whiteSpace: 'nowrap',  overflow: 'ellipsis'}}>
                    {title}
                </div>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={6}>
                        <div style={{fontSize: '.75em', color: 'white'}}>
                            Supply
                        </div>
                        <div style={{fontSize: '.75em', color: '#FF9900'}}>
                            {supply ? supply : 'n/a'}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{fontSize: '.75em', color: 'white'}}>
                            Holders
                        </div>
                        <div style={{fontSize: '.75em', color: '#FF9900'}}>
                            {holders ? holders : 'n/a'}
                        </div>
                    </Grid>
                </Grid>
                <Avatar
                    alt={title}
                    src={imageSrc}
                    sx={{
                        width: '10vw',
                        height: '10vw',
                        margin: '10% auto 6px auto',
                        backgroundColor: 'white',
                    }}
                    onClick={onClick}
                />
                <br/>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                        <ColorButton2 color="primary">
                            View Hub
                        </ColorButton2>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ExplorerAssetCard;
