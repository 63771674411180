import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <div className="footer">
            <div className="icons">
                <a href="https://twitter.com/ordia_xyz" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faXTwitter} size="2x" />
                </a>
                <a href="https://discord.gg/RrFF8tcMfe" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faDiscord} size="2x" />
                </a>
            </div>
        </div>
    );
};

export default Footer;