// Parse the OP_RETURN data based on the custom Glyph protocol format

// Helper function to convert hex data to readable text
export const hexToAscii = (hex: string): string => {
    let str = '';
    for (let i = 0; i < hex.length; i += 2) {
        const hexVal = hex.substring(i, i + 2);
        const charCode = parseInt(hexVal, 16);

        // Detect empty space or non-printable characters and treat them as spaces
        if (charCode === 0x20 || charCode === 0x2A || (charCode >= 0x00 && charCode <= 0x1F) || charCode === 0x7F) {
            str += ' '; // Treat control characters as spaces
        } else {
            str += String.fromCharCode(charCode);
        }
    }
    return str.trim(); // Trim any extra whitespace from the start or end
};

export interface GlyphData {
    id: string;
    fromAddress: string;
    toAddress: string;
    message: string;
    timestamp: string;
    contentType: string;
    glyph_id: string;
}

export const parseGlyphData = (dataHex: string, txHash: string): GlyphData | null => {
    const asciiData = hexToAscii(dataHex);

    // Ensure it starts with the protocol identifier "GLYPH"
    if (!asciiData.startsWith("GLYPH")) {
        return null;
    }

    // Parse the custom Glyph data (assumed to be space-separated)
    const parts = asciiData.split(' ');
    if (parts.length < 6) return null; // Ensure we have all required parts

    const version = parts[1];           // "01" for example
    const fromAddress = parts[2];       // Sender address
    const toAddressOrRealm = parts[3];  // Recipient address or Realm
    const message = parts.slice(4, parts.length - 1).join(' ');
    const timestamp = parts[parts.length - 1];         // Timestamp in the transaction

    return {
        id: txHash,
        fromAddress: fromAddress,
        toAddress: toAddressOrRealm,
        message: message,
        timestamp: timestamp,
        contentType: 'text/plain', // Assuming plain text for now
        glyph_id: ''
    };
};