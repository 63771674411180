import React, {useCallback, useEffect, useState} from "react";
import {useOrdConnect, useSignMessage} from "@ordzaar/ord-connect";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RocketIcon from '@mui/icons-material/Rocket';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {HowToVoteOutlined} from "@mui/icons-material";

import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';

type HubProposalProps = {
    title: string;
    description: string;
    id: number;
    iconStyle: string;
    isOwner: boolean;
    userAddress: string | null;

    fetchProposals: () => void;
}

function renderIcon(param: string) {
    switch(param) {
        case 'Endeavors':
            return <RocketIcon style={{color: '#87CEEB', fontSize: '2rem', marginLeft: '-8px'}}/>;
        case 'Locations':
            return <LocationOnIcon style={{color: '#87CEEB', fontSize: '2rem', marginLeft: '-8px'}}/>;
        case 'Challenges':
            return <EmojiEventsIcon style={{color: '#87CEEB', fontSize: '2rem', marginLeft: '-8px'}}/>;
        default:
            return <QuestionMarkIcon/>;
    }
}

type VoteType = {
    id: number;
    proposal_id: number;
    user_address: string;
    signed_msg: string
}

const detailNameStyle = {padding: '4px 0px', fontSize: '1em', textAlign: 'left'};
const detailValueStyle = {padding: '4px 0px', fontSize: '1em', textAlign: 'right', overflow: 'hidden'}


const HubProposal =  ({ id, title, isOwner, description, iconStyle, fetchProposals, userAddress}: HubProposalProps) => {
    const { address, wallet, publicKey } = useOrdConnect();
    const { signMsg, error: signMessageError } = useSignMessage();
    const [signed, setSigned] = useState<string|null>();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('');

    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const [votes, setVotes] = useState<VoteType[]>([]);
    const [voteMap, setVoteMap] = useState<Map<string,string>>(new Map());
    const [loading, setLoading] = useState(false);

    // Errors
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if (!id) {
            setDialogContent('Please enter a valid Proposal ID before attempting to delete.');
            setOpen(true);
            return;
        }
        setDialogContent(`Are you sure you want to delete the proposal? This action cannot be undone.`);
        setOpen(true);
    };

    const handleOpenVoteSignature = (signature: string) => {
        setDialogContent(signature);
        setOpen(true);
    };

    const fetchVotes = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/votes/${id}`);
            setVotes(response.data);
            setLoading(false);
            const voteMap: Map<string, string> = new Map(response.data.map((obj: VoteType) => [obj.user_address, obj.signed_msg]));
            setVoteMap(voteMap);
        } catch (err) {
            setLoading(false);
        }
    };

    // TODO: Handle response from fetchVotes()
    useEffect(() => {
        fetchVotes();
    }, [address]);

    const handleSignMessage = useCallback(async () => {
        if (!address.ordinals) {
            throw new Error("No payment address");
        }

        const signed = await signMsg(
            address.ordinals,
            description,
        );

        setSigned(signed);
        handleVote(signed).then((res) => {
            const voteMap: Map<string, string> = new Map();
            voteMap.set(address.ordinals ? address.ordinals : '', signed ? signed : '');
            setVoteMap(voteMap);
        }).catch((err) => {
            setShowError(true);
            setErrorMsg('api network having issues, please try again later.')
            setStatus("Failed to sign vote: ");
        })
    }, [address.ordinals, signMsg]);

    const handleVote = async (signedMsg: string | null) => {
        const postData = {
            proposal_id: id,
            user_address: address.ordinals,
            signed_msg: signedMsg,
            public_key: publicKey.ordinals
        };

        axios.post(`${process.env.REACT_APP_SERVER_URL}/vote`, postData)
            .then(response => {
                fetchVotes();
            })
            .catch(error => {
                setShowError(true);
                setErrorMsg('api network having issues, please try again later.')
                setStatus('Failed to create vote');
            });
    };

    const handleIsExpanded = useCallback(async () => {setIsExpanded(!isExpanded) }, [isExpanded]);

    const handleDelete = async () => {
        if (!id) {
            setStatus('Error deleting proposal');
            return;
        }
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER_URL}/proposal/${id}`);
            setStatus('Proposal deleted successfully.');
            fetchProposals();
        } catch (error) {
            setShowError(true);
            setErrorMsg('api network having issues, please try again later.')
            setStatus('Failed to delete proposal. Please try again.');
        }
    };

    return (
        <Grid container>
            <Grid item xs={11} md={11}>
                <Grid container>
                    <Grid item xs={12} md={1} style={{paddingTop: '4px'}}>
                        {renderIcon(iconStyle)}
                    </Grid>
                    <Grid item xs={12} md={7} style={{fontSize: '1rem', padding: '6px 6px', paddingTop: '8px'}}>
                        {title}
                    </Grid>
                    <Grid item xs={12} md={4}  style={{fontSize: '1rem', padding: '6px 6px', paddingTop: '18px'}}>
                        <div style={{ width: '100%' }}>
                            <div style={{ width: '100%'}}>
                                <LinearProgress variant="determinate" value={votes ? votes.length : 0} style={{ color: '#FF9900' }} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={1} md={1} style={{textAlign: 'right', padding: '6px 6px'}} onClick={handleIsExpanded}>
                <ButtonBase>
                    { !isExpanded ? ( <ExpandMoreIcon/> ) : ( <ExpandLessIcon/> ) }
                </ButtonBase>
            </Grid>

            {
                isExpanded ? (
                    <Grid item xs={12} md={12} style={{padding: '4px 0px'}}>
                        <Grid container>
                            <Grid item xs={12} md={4} sx={detailNameStyle}>
                                <div>Description</div>
                            </Grid>
                            <Grid item xs={12} md={8} sx={detailValueStyle}>
                                <div>{description}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <div></div>
                )
            }

            {
                isExpanded ? (
                    <Grid item xs={12} md={12} style={{padding: '4px 0px'}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={6} sx={detailNameStyle}>
                                <div>Status</div>
                            </Grid>
                            <Grid item xs={12} md={6} sx={detailValueStyle}>
                                <div>{address.ordinals != null && voteMap.has(address.ordinals) ?
                                    <FiberManualRecordIcon style={{ color: 'red[500]' }} />
                                    :
                                    <FiberManualRecordIcon style={{ color: 'green' }} />
                                }</div>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <div></div>
                )
            }

            {
                isExpanded ? (
                    <Grid item xs={12} md={12}>
                        {
                            address && address.ordinals && voteMap.has(address.ordinals ? address.ordinals : '') ? (
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={6} sx={detailNameStyle}>
                                        <div>See Vote</div>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={detailValueStyle}>
                                        {voteMap.get(address.ordinals)}
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={6} sx={detailNameStyle}>
                                        <div>Vote</div>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={detailValueStyle}>
                                        <Tooltip title="Vote Yes">
                                            <HowToVoteOutlined onClick={handleSignMessage}/>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                ) : (
                    <div></div>
                )
            }

            {
                (isExpanded && isOwner) ? (
                    <Grid item xs={12} md={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={6} sx={detailNameStyle}>
                                <div>Delete Proposal</div>
                            </Grid>
                            <Grid item xs={12} md={6} sx={detailValueStyle}>
                                {
                                    !isLoading ? (
                                        <DeleteOutlineIcon onClick={handleOpen} style={{color: 'red'}}/>
                                    ) : (
                                        <CircularProgress />
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <div></div>
                )
            }

            {
                (isExpanded && isOwner) ? (
                    <Grid item xs={12} md={12} style={{paddingTop: '.75rem'}}>
                        {status}
                    </Grid>
                ) : (
                    <div></div>
                )
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

        </Grid>
    )
};

export default HubProposal;